<template>
  <div class="video-player">
    <link rel="stylesheet" href="https://cdn.plyr.io/3.6.8/plyr.css" />
    <div>
      <div v-show="currentPlayer === 'plyr1'" :class="{ 'plyr-controls': playerClass }">
        <video preload="metadata" id="plyr1" playsinline controls></video>
      </div>
      <div v-show="currentPlayer === 'plyr2'" :class="{ 'plyr-controls': playerClass }">
        <video preload="metadata" id="plyr2" playsinline controls></video>
      </div>
      <div v-show="currentPlayer === 'plyr3'" :class="{ 'plyr-controls': playerClass }">
        <video preload="metadata" id="plyr3" playsinline controls></video>
      </div>
    </div>
    <div class="video-player__play-buttons">
      <template v-if="isV2">
        <Controls
          has-retro
          :container-width="containerWidth"
          :is-play-seeker-active="isPlaySeekerActive"
          @click-jump="onMoveToPlayClicked"
          @click-retro="$emit('click-retro')"
          @change-type="$emit('change-type', $event)"
          @change-retro="$emit('change-retro', $event)"
        />
      </template>
      <template v-else>
        <MoveToPlayButton
          v-for="buttonType of buttons"
          :key="buttonType.type"
          :button-type="buttonType.type"
          is-v2
          @click="onMoveToPlayClicked(buttonType.type)"
        >
          <slot>
            <div class="move-to-play-button__text">{{ buttonType.text }}</div>
            <div class="move-to-play-button__text-mobile">{{ buttonType.textMobile }}</div>
            <div class="move-to-play-button__icon">
              <img :src="buttonType.imgSrc" />
            </div>
          </slot>
        </MoveToPlayButton>
      </template>
    </div>
  </div>
</template>

<script>
import MoveToPlayButton from '@/components/Sections/MediaPlayer/MoveToPlayButton';

export default {
  name: 'VideoPlayer',
  components: {
    MoveToPlayButton,
    Controls: () => import('@/components/Sections/MediaPlayer-v2/Controls'),
  },
  props: {
    playerQueue: {
      type: Array,
      default: () => [],
    },
    isV2: {
      type: Boolean,
      default: false,
    },
    containerWidth: {
      type: Number,
      default: 1024,
    },
    gameIdSelected: {
      type: Number,
      default: 0,
    },
    isPlaySeekerActive: {
      type: Boolean,
      default: false,
    },
    concatPlays: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPlayer: 'plyr1',
      url1:
        'https://golstats.blob.core.windows.net/renders-correccion-2020/64930-12441-0002-Silva%20Diaz,%20Jordan-Pases%20acertados%20c.%20propia.mp4',
      url2: 'https://golstatsbets.blob.core.windows.net/video-player/67568_1.mp4',
      url3: 'https://golstats.blob.core.windows.net/video-reproductor-b2c/67568_1.mp4',
      baseUrl1: 'https://golstats.blob.core.windows.net/video-reproductor-b2c',
      baseUrl: 'https://golstatsbets.blob.core.windows.net/video-player',
      plyr1Src: '',
      plyr2Src: '',
      plyr3Src: '',
      plyr4Src: '',
      plyr1: null,
      plyr2: null,
      plyr3: null,
      plyr4: null,
      buttonTypes: [
        {
          type: 'last',
          text: 'Jugada Anterior',
          textMobile: 'Jugada Ant.',
          imgSrc: 'icons/icn-player-back.svg',
        },
        {
          type: 'next',
          text: 'Siguiente Jugada',
          textMobile: 'Sig. Jugada',
          imgSrc: 'icons/icn-player-next.svg',
        },
      ],
      buttonTypesV2: [
        {
          type: 'last',
          text: 'Anterior',
          textMobile: 'Anterior',
          imgSrc: 'icons/icn-player-back-green.svg',
        },
        {
          type: 'next',
          text: 'Siguiente',
          textMobile: 'Siguiente',
          imgSrc: 'icons/icn-player-next-green.svg',
        },
      ],
      currentPlayIndex: 0,
      currentPlay: {},
      alreadyChanged: false,
      pivotPlayers: {
        current: null,
        last: null,
        next: null,
      },
      maxPlaysWithoutInteraction: 100,
      // todo: check on loaded data called twice at first load
      autoPlaysCount: -1,
    };
  },
  computed: {
    lastPlayIndex() {
      return this.currentPlayIndex - 1;
    },
    nextPlayIndex() {
      return this.currentPlayIndex + 1;
    },
    playerClass() {
      return this.$route.name === 'mediaplayer2';
    },
    buttons() {
      return this.isV2 ? this.buttonTypesV2 : this.buttonTypes;
    },
  },
  watch: {
    playerQueue: {
      immediate: true,
      handler(newValue) {
        this.autoPlaysCount = -1;
        this.resetPlayersSrc();
        if (newValue && newValue.length) {
          // if (this.concatPlays) {
          //   this.currentPlayIndex = 0;
          // }
          this.setPlyrSrc();
        }
      },
    },
    currentPlay: {
      deep: true,
      handler(newValue) {
        this.$emit('play-changed', newValue);
      },
    },
    autoPlaysCount(newValue) {
      if (newValue > this.maxPlaysWithoutInteraction) {
        this.pausePlayers();
        this.autoPlaysCount = 0;
        this.$emit('max-inactivity-reached');
      }
    },
    gameIdSelected(newValue) {
      if (!(this.currentPlayIndex === 0 && this.playerQueue[this.currentPlayIndex].gameId === newValue)) {
        this.moveToGamePlays(newValue);
      }
    },
    currentPlayIndex(newValue) {
      this.$emit('index-change', newValue);
    },
  },
  methods: {
    resetCurrentPlayIndex() {
      this.currentPlayIndex = 0;
    },
    plyrInit() {
      for (let i = 1; i < 4; i++) {
        const plyrElement = `plyr${i}`;
        this[plyrElement] = new window.Plyr(document.getElementById(plyrElement), this.getPlyrOptions());
        this[plyrElement].on('loadeddata', this.getOnLoadedData(this[plyrElement], plyrElement));
        this[plyrElement].on('timeupdate', this.onTimeUpdate(plyrElement));
      }
    },
    setAutoPlaysCount(autoPlaysCount) {
      this.autoPlaysCount = autoPlaysCount;
    },
    getOnLoadedData(plyr, plyrElement) {
      const vm = this;
      return () => {
        if (plyr.duration > 0) {
          plyr.currentTime = plyr.gamePlay.startSeconds;
        }
        if (vm.currentPlayer === plyrElement) {
          vm.playCurrentVideoPlayer();
        }
      };
    },
    getPlyrOptions() {
      return {
        autoplay: false,
        speed: { selected: 1, options: [1, 2, 3] },
        invertTime: false,
        muted: false,
        seekTime: 5,
        controls: this.getVideoPlayerOptions(),
      };
    },
    getVideoPlayerOptions() {
      if (this.$route.name === 'mediaplayer' || this.$route.name === 'playSeeker') {
        return [
          'play-large', // The large play button in the center
          'play', // Play/pause playback
          'progress', // The progress bar and scrubber for playback and buffering
          'current-time', // The current time of playback
          'duration', // The full duration
          'volume', // The volume
          // 'fullscreen', // Toggle fullscreen
        ];
      }
      return [
        'play-large', // The large play button in the center
        'rewind', // Rewind by the seek time (default 10 seconds)
        'play', // Play/pause playback
        'fast-forward', // Fast forward by the seek time (default 10 seconds)
        'current-time', // The current time of playback
        'volume',
        // 'fullscreen', // Toggle fullscreen
      ];
    },
    playCurrentVideoPlayer() {
      const currentVideoPlayer = this[this.pivotPlayers.current];
      if (currentVideoPlayer) {
        this.autoPlaysCount += 1;
        currentVideoPlayer.muted = false;
        currentVideoPlayer.play();
      }
    },
    moveToGamePlays(gameId) {
      let firstGamePlayIndex = 0;
      for (let i = 0; i < this.playerQueue.length; i++) {
        firstGamePlayIndex = i;
        if (this.playerQueue[i].gameId === gameId) {
          break;
        }
      }
      this.autoPlaysCount = -1;
      this.resetPlayersSrc();
      this.currentPlayIndex = firstGamePlayIndex;
      this.setPlyrSrc();
    },
    onTimeUpdate(plyrElement) {
      const vm = this;
      return () => {
        if (plyrElement !== vm.currentPlayer) return;
        if (vm[vm.currentPlayer].currentTime >= vm[vm.currentPlayer].gamePlay.endSeconds) {
          if (!vm[vm.currentPlayer].paused) {
            vm.onNextPlayClicked();
          }
        }
      };
    },
    onNextPlayClicked(isFromButton = false) {
      this.$emit('play-iteration', {
        iterationTime: isFromButton ? this.currentPlay.currentTime : null,
        userAction: isFromButton ? 0 : 1,
        dateTimeIsoString: new Date().toISOString().split('.')[0],
      });
      if (isFromButton) {
        this.autoPlaysCount = 0;
      }
      this.pausePlayers();
      // if (this.currentPlayIndex < this.playerQueue.length - 1) {
      const currentAux = this.pivotPlayers.current;
      this.currentPlayIndex += 1;
      this.currentPlayer = this.pivotPlayers.next;
      this.pivotPlayers.current = this.currentPlayer;
      this.pivotPlayers.next = this.pivotPlayers.last;
      this.pivotPlayers.last = currentAux;
      this.setCurrentPlay();
      this.playCurrentVideoPlayer();
      this.setUpNewNextAndLast('next');
      // } else {
      //   this.resetPlayersSrc();
      //   this.$emit('playlist-ended');
      // }
    },
    onLastPlayClicked() {
      // this.$emit('play-iteration', {
      //   iterationTime: this.currentPlay.currentTime,
      //   userAction: 2,
      //   dateTimeIsoString: new Date().toISOString(),
      // });
      this.autoPlaysCount = 0;
      this.pausePlayers();
      if (this.currentPlayIndex > 0) {
        this.currentPlayIndex -= 1;
        const currentAux = this.pivotPlayers.current;
        this.currentPlayer = this.pivotPlayers.last;
        this.pivotPlayers.current = this.currentPlayer;
        this.pivotPlayers.last = this.pivotPlayers.next;
        this.pivotPlayers.next = currentAux;
        this.playCurrentVideoPlayer();
        this.setCurrentPlay();
        this.setUpNewNextAndLast('last');
      } else {
        this[this.currentPlayer].currentTime = this[this.currentPlayer].gamePlay.startSeconds;
      }
    },
    resetPlayersSrc() {
      Object.values(this.pivotPlayers).forEach(value => {
        if (value) {
          const videoPlayer = this[this.pivotPlayers[value]];
          if (videoPlayer) {
            this[this.pivotPlayers[value]].pause();
            this[this.pivotPlayers[value]].removeAttribute('src'); // empty source
            this[this.pivotPlayers[value]].load();
          }
        }
      });
    },
    pausePlayers() {
      this[this.pivotPlayers.current].pause();
      this[this.pivotPlayers.last].pause();
      this[this.pivotPlayers.next].pause();
    },
    setCurrentPlay() {
      this.currentPlay = this.playerQueue[this.currentPlayIndex];
    },
    setUpNewNextAndLast(action) {
      if (action === 'next') {
        const nextGamePlay = this.playerQueue[this.nextPlayIndex];
        const lastGamePlay = this[this.pivotPlayers.last].gamePlay;
        if (lastGamePlay) {
          this[this.pivotPlayers.last].currentTime = lastGamePlay.startSeconds;
          this[this.pivotPlayers.last].pause();
        }
        if (nextGamePlay) {
          this[this.pivotPlayers.next].gamePlay = nextGamePlay;
          this[this.pivotPlayers.next].source = this.getSrcObject(nextGamePlay);
        }
      } else if (action === 'last') {
        const nextGamePlay = this[this.pivotPlayers.next].gamePlay;
        if (nextGamePlay) {
          this[this.pivotPlayers.next].currentTime = nextGamePlay.startSeconds;
          this[this.pivotPlayers.next].pause();
        }
        if (this.currentPlayIndex > 0) {
          const lastGamePlay = this.playerQueue[this.lastPlayIndex];
          this[this.pivotPlayers.last].gamePlay = lastGamePlay;
          this[this.pivotPlayers.last].source = this.getSrcObject(lastGamePlay);
        }
      }
    },
    stopVideoPlayers() {
      if (this[this.pivotPlayers.current]) {
        this[this.pivotPlayers.current].stop();
      }
      if (this[this.pivotPlayers.next]) {
        this[this.pivotPlayers.next].stop();
      }
      if (this[this.pivotPlayers.last]) {
        this[this.pivotPlayers.last].stop();
      }
    },
    setPlyrSrc() {
      try {
        this.stopVideoPlayers();
        if (this.playerQueue && this.playerQueue.length) {
          this.currentPlayer = 'plyr1';
          this.pivotPlayers.current = 'plyr1';
          this.pivotPlayers.next = 'plyr2';
          this.pivotPlayers.last = 'plyr3';
          const firstGamePlay = this.playerQueue[this.currentPlayIndex];
          const nextGamePlay = this.playerQueue[this.nextPlayIndex];
          const lastGamePlay = this.playerQueue[this.lastPlayIndex];
          this.plyr1.source = this.getSrcObject(firstGamePlay);
          this.plyr1.gamePlay = firstGamePlay;
          this.currentPlay = firstGamePlay;
          this.plyr1.nextGamePlay = nextGamePlay;
          if (nextGamePlay) {
            this.plyr2.source = this.getSrcObject(nextGamePlay);
            this.plyr2.gamePlay = nextGamePlay;
          }
          if (lastGamePlay) {
            this.plyr3.source = this.getSrcObject(lastGamePlay);
            this.plyr3.gamePlay = lastGamePlay;
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getSrcObject(gamePlay) {
      return {
        type: 'video',
        sources: [
          {
            src: `${this.baseUrl}/${gamePlay.gameId}_1.mp4`,
            type: 'video/mp4',
            size: 720,
          },
        ],
      };
    },
    play() {
      this.plyr1.currentTime = this.plyr1.gamePlay.startSeconds;
      this.plyr1.play();
    },
    onMoveToPlayClicked(type) {
      if (type === 'next') {
        this.onNextPlayClicked(true);
      } else if (type === 'last') {
        this.onLastPlayClicked();
      }
    },
    fetchPlyr() {
      let script = document.createElement('script');
      script.setAttribute('src', 'https://cdn.plyr.io/3.6.8/plyr.js');
      script.setAttribute('type', 'application/javascript');
      document.body.appendChild(script);

      // now wait for it to load...
      script.onload = () => {
        this.plyrInit();
      };
    },
  },
  mounted() {
    try {
      this.fetchPlyr();
    } catch (e) {
      throw new Error(e);
    }
  },
};
</script>

<style scoped lang="scss">
.video-player {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__play-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.move-to-play-button__icon {
  height: 40px;
  width: 40px;

  @media screen and (max-width: 640px) {
    height: 30px;
    width: 30px;
  }
}

.move-to-play-button__text {
  font-size: 1.3rem;
  font-family: Avenir-Pro-Medium, sans-serif;

  @media screen and (max-width: 640px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 420px) {
    display: none;
  }

  &-mobile {
    @media screen and (min-width: 420px) {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.video-player {
  & div.plyr-controls {
    & div.plyr__controls > button.plyr__controls__item.plyr__control {
      &:nth-child(1) {
        margin-right: 0 !important;
      }
      &:nth-child(3) {
        margin-right: auto !important;
      }
    }
  }
}
</style>
