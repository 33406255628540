<template>
  <div class="move-play-button" :style="typeStyle" @click="$emit('click', buttonType)" disabled>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MovePlayButton',
  props: {
    buttonType: {
      type: String,
      default: 'next',
    },
  },
  computed: {
    typeStyle() {
      const typeStyle = {};
      if (this.buttonType === 'next') {
        typeStyle.flexDirection = 'row';
        typeStyle.justifyContent = 'flex-end';
      } else {
        typeStyle.flexDirection = 'row-reverse';
        typeStyle.justifyContent = 'flex-end';
      }
      return typeStyle;
    },
  },
};
</script>

<style scoped lang="scss">
.move-play-button {
  margin: 1rem 0;
  width: 16.25rem;
  height: 3.5rem;
  border-radius: 8px;
  background-color: #464646;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;

  @media screen and (max-width: 640px) {
    width: 10.8rem;
    height: 2.6rem;
  }

  @media screen and (max-width: 420px) {
    padding: 0 0.2rem;
    font-size: 0.9rem;
    width: 8rem;
  }
}
</style>
