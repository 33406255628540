<template>
  <div class="media-player">
    <div class="mediaplayer">
      <div class="media-player__mode">
        <div class="media-player__mode__options">
          <div class="media-player__mode__options__background" :class="{ retro: isRetroMode }" />
          <div
            v-for="mode in playerModes"
            :key="mode.id"
            :class="{ active: playerModeSelected === mode.id }"
            @click="onPlayerModeClicked(mode.id)"
          >
            {{ mode.name }}
          </div>
        </div>
      </div>
      <div class="media-player__categories">
        <div
          class="media-player__categories__goals"
          :class="{ 'button--active': playsType === playsTypeOptions[0] }"
          @click="onPlaysTypeClicked(playsTypeOptions[0])"
        >
          <div>
            Goles
          </div>
        </div>
        <div
          class="media-player__categories__best-plays"
          :class="{ 'button--active': playsType === playsTypeOptions[1] }"
          @click="onPlaysTypeClicked(playsTypeOptions[1])"
        >
          <div>
            Jugadas Relevantes
          </div>
        </div>
      </div>
      <VideoPlayer
        v-show="!showImage"
        ref="videoPlayer"
        :player-queue="playerQueue"
        @play-changed="onPlayChanged"
        @max-inactivity-reached="onMaxInactivityReached"
        @playlist-ended="onPlaylistEnded"
      />
      <div class="media-player__image" v-show="showImage">
        <div class="media-player__image__text"><span>Por Reproducir:</span> {{ seasonName }}</div>
      </div>
      <div class="mediaplayer-player"></div>
      <FilterMenu
        @openmodal="openmodal"
        :current-plays-category="currentPlaysCategory"
        :play-info="playInfo"
        :are-goals-selected="playsType === playsTypeOptions[0]"
        :is-retro-mode="isRetroMode"
      />
      <FilterModal
        :show="showModal"
        :is-retro-mode="isRetroMode"
        @close="closeModal"
        @player-selected="onPlayerSelected"
        @team-selected="onTeamSelected"
      />
    </div>
    <Confirm
      question="Hemos detectado un período largo de inactividad. ¿Sigues ahí?"
      :showModalConfirm="showModalConfirm"
      @onConfirm="confirmModalConfirm"
      @onCancel="closeModalConfirm"
      :textConfirm="'Continuar viendo'"
      :textCancel="'Salir'"
    />
  </div>
</template>

<script>
import FilterMenu from '@/components/Sections/MediaPlayer/FilterMenu';
import VideoPlayer from '@/components/Sections/MediaPlayer/VideoPlayer';
import FilterModal from '@/components/Sections/MediaPlayer/FilterModal';
import Confirm from '@/components/Modals/ConfirmMediaPlayer';
import axios from 'axios';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
const playContentIndex = (function getPlayContentIndex() {
  const fields = [
    'categoryId',
    'playerId',
    'teamId',
    'gameId',
    'gameDate',
    'gameHour',
    'matchDayId',
    'matchLapse',
    'momentOfPlay',
    'startTime',
    'endTime',
    'syncStartFirstTime',
    'syncEndFirstTime',
    'syncStartSecondTime',
    'syncEndSecondTime',
    'syncStartFirstExtraTime',
    'syncEndFirstExtraTime',
    'syncStartSecondExtraTime',
    'syncEndSecondExtraTime',
    'syncStartPenals',
    'syncEndPenals',
    'jersey',
  ];
  return fields.reduce((accumulator, field, index) => {
    accumulator[index] = field;
    return accumulator;
  }, {});
})();
let playsPerGame = null;
const playsTypeOptions = ['goals', 'bestPlays'];
const allCategoriesNamesAndWeighing = {
  1: { name: 'Oportunidad de Gol', weighing: 3 },
  2: { name: 'Gol', weighing: 1 },
  3: { name: 'Autogol', weighing: 2 },
  4: { name: 'Tarjeta Amarilla', weighing: 5 },
  5: { name: 'Tarjeta Roja', weighing: 4 },
  6: { name: 'Penal Recibido', weighing: 6 },
  7: { name: 'Atajada', weighing: 8 },
  8: { name: 'Asistencia', weighing: 9 },
  9: { name: 'Participación en Gol', weighing: 14 },
  10: { name: 'Sobresaliente', weighing: 20 },
  11: { name: 'Centro Rematado', weighing: 11 },
  12: { name: 'Corner Rematado', weighing: 12 },
  13: { name: 'Tiro Libre Rematado', weighing: 13 },
  14: { name: '1vs1 Exitoso Ofensivo', weighing: 16 },
  15: { name: '1vs1 Exitoso Defensivo', weighing: 18 },
  16: { name: 'Pase Filtrado', weighing: 15 },
  17: { name: 'Balón ganado en Área Propia', weighing: 19 },
  18: { name: 'Balón Recuperado', weighing: 17 },
  19: { name: 'Gol Recibido', weighing: 7 },
  20: { name: 'Origen de Gol', weighing: 10 },
};
const goalCategories = {
  ['12-55']: allCategoriesNamesAndWeighing['2'],
};
const bestPlaysCategories = {
  games: {
    ['607-608']: allCategoriesNamesAndWeighing['1'],
    ['12-55']: allCategoriesNamesAndWeighing['2'],
    ['54-35']: allCategoriesNamesAndWeighing['3'],
    ['14-52']: allCategoriesNamesAndWeighing['4'],
    ['15-53']: allCategoriesNamesAndWeighing['5'],
    ['351-477']: allCategoriesNamesAndWeighing['6'],
  },
  team: {
    ['607-608']: allCategoriesNamesAndWeighing['1'],
    ['12-55']: allCategoriesNamesAndWeighing['2'],
    ['54-35']: allCategoriesNamesAndWeighing['3'],
    ['14-52']: allCategoriesNamesAndWeighing['4'],
    ['15-53']: allCategoriesNamesAndWeighing['5'],
    ['351-477']: allCategoriesNamesAndWeighing['6'],
    ['40-257']: allCategoriesNamesAndWeighing['19'],
    ['39']: allCategoriesNamesAndWeighing['7'],
  },
  player: {
    regular: {
      ['12']: allCategoriesNamesAndWeighing['2'],
      ['35']: allCategoriesNamesAndWeighing['3'],
      ['10']: allCategoriesNamesAndWeighing['8'],
      ['27']: allCategoriesNamesAndWeighing['9'],
      ['607']: allCategoriesNamesAndWeighing['1'],
      ['61']: allCategoriesNamesAndWeighing['20'],
      ['519']: allCategoriesNamesAndWeighing['10'],
      ['98-101-109-112']: allCategoriesNamesAndWeighing['11'],
      ['121-122-127-128-135-136-141-142']: allCategoriesNamesAndWeighing['12'],
      ['161-170-179']: allCategoriesNamesAndWeighing['13'],
      ['702']: allCategoriesNamesAndWeighing['14'],
      ['730']: allCategoriesNamesAndWeighing['15'],
      ['351']: allCategoriesNamesAndWeighing['6'],
      ['217']: allCategoriesNamesAndWeighing['16'],
      ['211-232-235-239-242-282-284-289-291-296']: allCategoriesNamesAndWeighing['17'],
      ['485-486-487-488']: allCategoriesNamesAndWeighing['18'],
      ['14']: allCategoriesNamesAndWeighing['4'],
      ['15']: allCategoriesNamesAndWeighing['5'],
      ['257']: allCategoriesNamesAndWeighing['19'],
    },
    goalkeeper: {
      ['55']: allCategoriesNamesAndWeighing['2'],
      ['324']: allCategoriesNamesAndWeighing['8'],
      ['323']: allCategoriesNamesAndWeighing['9'],
      ['608']: allCategoriesNamesAndWeighing['1'],
      ['39']: allCategoriesNamesAndWeighing['7'],
      ['774']: allCategoriesNamesAndWeighing['14'],
      ['802']: allCategoriesNamesAndWeighing['15'],
      ['52']: allCategoriesNamesAndWeighing['4'],
      ['53']: allCategoriesNamesAndWeighing['5'],
      ['40']: allCategoriesNamesAndWeighing['19'],
      ['54']: allCategoriesNamesAndWeighing['3'],
    },
  },
};

export default {
  components: { FilterMenu, FilterModal, VideoPlayer, Confirm },

  data() {
    return {
      showModalConfirm: false,
      showModal: false,
      seasonId: 0,
      plays: [],
      playerQueue: [],
      playsType: playsTypeOptions[0],
      playsTypeOptions: playsTypeOptions,
      playInfo: {},
      teamsMap: null,
      gamesMap: null,
      mediaPlayerMode: 'games', // values can be 'games', 'team' or 'player'
      playerPosition: 0,
      playlistTournament: null,
      showImage: false,
      seasonName: '',
      playlistSeasonIndex: 0,
      playlistSeasonIndexAux: 0,
      playerModes: {
        present: {
          id: 1,
          name: 'Actualidad',
        },
        retro: {
          id: 2,
          name: 'Retro',
        },
      },
      playerModeSelected: 1,
      isRetroModeApplied: false,
      currentTournament: { seasons: [] },
    };
  },
  computed: {
    ...mapGetters('mediaGeneral', [
      'getSeasonIdOnly',
      'getTeamId',
      'getPlayerId',
      'seasonPlayers',
      'selectedTournament',
      'isPlayerGoalkeeper',
    ]),
    ...mapState('mediaGeneral', ['tournaments', 'seasonID', 'retroSeason', 'retroTournament']),
    currentCategories() {
      return this.playsType === playsTypeOptions[1]
        ? this.getBestCategoriesIds()
        : Object.keys(goalCategories).reduce((accumulator, categoryId) => {
            categoryId.split('-').forEach(categoryIdString => {
              accumulator.push(Number(categoryIdString));
            });
            return accumulator;
          }, []);
    },
    currentPlaysCategory() {
      if (this.playsType) {
        return this.playsType === playsTypeOptions[0] ? 'goles' : 'jugadas relevantes';
      }
      return '';
    },
    allCategories() {
      const allCategories = new Map();
      Object.entries(goalCategories).forEach(([key, value]) => {
        allCategories.set(Number(key), value);
      });
      this.setBestCategoriesNames(allCategories);
      return allCategories;
    },
    queryCategories() {
      return this.currentCategories.reduce((accumulator, categoryId, index) => {
        // eslint-disable-next-line no-param-reassign
        accumulator += `${index > 0 ? '&' : ''}categories=${categoryId}`;
        return accumulator;
      }, '');
    },
    isRetroMode() {
      return this.playerModeSelected === this.playerModes.retro.id;
    },
  },
  watch: {
    selectedTournament: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.playlistTournament = newValue;
          this.playlistSeasonIndex = 0;
        }
      },
    },
    seasonID: {
      deep: true,
      handler(newValue) {
        if (newValue && !this.isRetroMode) {
          this.playlistSeasonIndexAux = newValue.index;
        }
      },
    },
    retroSeason: {
      deep: true,
      handler(newValue) {
        if (newValue && this.isRetroMode) {
          this.playlistSeasonIndexAux = newValue.index;
        }
      },
    },
    retroTournament: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.tournament_id) {
          this.fetchInfo(false, newValue);
        }
      },
    },
    async tournaments(newValue) {
      if (newValue && newValue.length) {
        await this.fillSeasons(newValue);
      }
    },
    playsType() {
      this.fetchInfo(true, this.playlistTournament);
    },
    isRetroMode(newValue) {
      this.playlistSeasonIndex = 0;
      this.setPlayer(null);
      this.setTeam(null);
      if (newValue) {
        this.showModal = true;
        if (this.retroTournament && this.retroTournament.tournament_id) {
          this.fetchInfo(false, this.retroTournament);
        }
      } else {
        this.fetchInfo(false, this.currentTournament);
      }
    },
  },
  methods: {
    ...mapActions('mediaGeneral', ['fetchTournaments', 'fetchSeasonsGamesAndTeams', 'getPlayersBySeasons']),
    ...mapMutations('mediaGeneral', ['setTeam', 'setPlayer']),
    closeModalConfirm() {
      this.showModalConfirm = false;
    },
    confirmModalConfirm() {
      this.showModalConfirm = false;
      this.$refs.videoPlayer.playCurrentVideoPlayer();
    },
    openmodal() {
      this.$refs.videoPlayer.setAutoPlaysCount(0);
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async fillSeasons(tournaments) {
      try {
        if (tournaments) {
          const mxLeague = tournaments.find(tournament => tournament._id === 1);
          if (mxLeague) {
            this.currentTournament = mxLeague;
            await this.fetchInfo(false, this.currentTournament);
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getBestCategoriesIds() {
      let keys = null;
      if (this.mediaPlayerMode === 'player') {
        if (this.isPlayerGoalkeeper) {
          keys = Object.keys(bestPlaysCategories.player.goalkeeper);
        } else {
          keys = Object.keys(bestPlaysCategories.player.regular);
        }
      } else {
        keys = Object.keys(bestPlaysCategories[this.mediaPlayerMode]);
      }
      return keys.reduce((accumulator, key) => {
        key.split('-').forEach(categoryIdString => {
          accumulator.push(Number(categoryIdString));
        });
        return accumulator;
      }, []);
    },
    setBestCategoriesNames(namesMap) {
      let entries = null;
      if (this.mediaPlayerMode === 'player') {
        if (this.isPlayerGoalkeeper) {
          entries = Object.entries(bestPlaysCategories.player.goalkeeper);
        } else {
          entries = Object.entries(bestPlaysCategories.player.regular);
        }
      } else {
        entries = Object.entries(bestPlaysCategories[this.mediaPlayerMode]);
      }
      entries.forEach(([key, value]) => {
        key.split('-').forEach(categoryIdString => {
          namesMap.set(Number(categoryIdString), value);
        });
      });
    },
    async fetchPlays() {
      try {
        let queryParams = '';
        let et = '';
        if (this.getTeamId || this.getPlayerId) {
          et = '&';
          if (this.getPlayerId) {
            queryParams += `player=${this.getPlayerId}`;
          } else {
            queryParams += `team=${this.getTeamId}`;
          }
        }
        const response = await axios.get(
          `https://gpela9u46i.execute-api.us-west-2.amazonaws.com/prod/videos/plays/seasons/${this.seasonId}?${this.queryCategories}${et}${queryParams}`,
        );
        if (response.data && response.data.plays) {
          return response.data.plays;
        }
        return [];
      } catch (e) {
        throw new Error(e);
      }
    },
    onPlaysTypeClicked(playsType) {
      this.playsType = playsType;
    },
    getPlayTime(gamePlay, timeProp) {
      let syncTime = '';
      let minutesToSubtract = 0;
      switch (gamePlay.matchLapse) {
        case 1:
          syncTime = gamePlay.syncStartFirstTime;
          break;
        case 2:
          syncTime = gamePlay.syncStartSecondTime;
          minutesToSubtract = 45;
          break;
        case 3:
          syncTime = gamePlay.syncStartFirstExtraTime;
          minutesToSubtract = 90;
          break;
        case 4:
          syncTime = gamePlay.syncStartSecondExtraTime;
          minutesToSubtract = 105;
          break;
        case 5:
          syncTime = gamePlay.syncStartPenals;
          minutesToSubtract = 120;
          break;
      }
      if (!syncTime || syncTime === '0') {
        syncTime = '00:00';
      }
      return this.getTimeToSeconds(syncTime) + (this.getTimeToSeconds(gamePlay[timeProp]) - minutesToSubtract * 60);
    },
    getTimeToSeconds(timeString) {
      const dotIndex = timeString.indexOf('.');
      const stringAux = dotIndex > -1 ? timeString.substring(0, timeString.indexOf('.')) : timeString;
      const [seconds, minutes, hours] = stringAux.split(':').reverse();

      return (hours ? Number(hours) * 60 * 60 : 0) + Number(minutes) * 60 + Number(seconds);
    },
    formatPlays() {
      playsPerGame = new Map();
      this.plays.forEach(play => {
        const playObject = play.reduce((accumulator, value, index) => {
          accumulator[playContentIndex[index]] = value;
          return accumulator;
        }, {});
        playObject.game = this.gamesMap.get(playObject.gameId) || { homeTeam: { name: '' }, awayTeam: { name: '' } };
        playObject.player = this.seasonPlayers.get(playObject.playerId);
        playObject.playerTeamLogo = this.teamsMap.get(playObject.player.teamId).logo;
        playObject.startSeconds = this.getPlayTime(playObject, 'startTime');
        playObject.endSeconds = this.getPlayTime(playObject, 'endTime');
        // playObject.gameId = 67588;
        const gameDateTime = new Date(playObject.gameDate);
        const [hours, minutes] = playObject.gameHour.split(':');
        gameDateTime.setHours(hours);
        gameDateTime.setMinutes(minutes);
        playObject.dateTimeMillis = gameDateTime.valueOf();
        if (!playsPerGame.has(playObject.gameId)) {
          playsPerGame.set(playObject.gameId, { dateTimeMillis: gameDateTime.valueOf(), plays: [playObject] });
        } else {
          const playsInGame = playsPerGame.get(playObject.gameId);
          playsInGame.plays.push(playObject);
        }
      });
      const playsPerGameArray = Array.from(playsPerGame.values());
      playsPerGameArray.sort((a, b) => {
        return b.dateTimeMillis - a.dateTimeMillis;
      });
      this.plays = playsPerGameArray.reduce((accumulator, playsInGame) => {
        playsInGame.plays.sort((a, b) => {
          return (
            Number(`${a.matchLapse}${a.startTime.replace(/:*\.*/g, '')}`) -
            Number(`${b.matchLapse}${b.startTime.replace(/:*\.*/g, '')}`)
          );
        });
        accumulator.push(...playsInGame.plays);
        return accumulator;
      }, []);
    },
    generatePlayerQueue(categoriesId) {
      const playsMap = new Map();
      this.playerQueue = this.plays.reduce((accumulator, play) => {
        // todo: add momentOfPlay and endTime
        const playKey = `${play.gameId}-${play.playerId}-${play.startTime}`;
        if (categoriesId.includes(play.categoryId) && play.startSeconds !== play.endSeconds) {
          const playAux = playsMap.get(playKey);
          if (!playAux) {
            const categoryAux = this.allCategories.get(play.categoryId);
            play.mainCategory = categoryAux;
            play.categoriesNames = categoryAux.name;
            playsMap.set(playKey, play);
            accumulator.push(play);
          } else {
            const categoryAux = this.allCategories.get(play.categoryId);
            if (categoryAux.weighing < playAux.mainCategory.weighing) {
              playAux.mainCategory = categoryAux;
            }
            playAux.categoriesNames += `, ${categoryAux.name}`;
          }
        }
        return accumulator;
      }, []);
    },
    onPlaylistEnded() {
      this.playlistSeasonIndex++;
      if (this.playlistSeasonIndex > this.playlistTournament.seasons.length - 1) {
        this.playlistSeasonIndex = 0;
      }
      this.seasonName = this.playlistTournament.seasons[this.playlistSeasonIndex].name;
      this.showImage = true;
      setTimeout(() => {
        this.fetchInfo(false, this.playlistTournament);
      }, 4000);
    },
    onMaxInactivityReached() {
      this.showModalConfirm = true;
    },
    checkSeason() {
      this.playlistSeasonIndex = this.playlistSeasonIndexAux;
      if (!this.isRetroModeApplied) {
        const isSameSeason = this.getSeasonIdOnly === this.seasonId;
        this.fetchInfo(isSameSeason, this.playlistTournament);
      } else {
        // todo: check retro behavior when videos ends
        const isSameSeason = this.retroSeason.id === this.seasonId;
        this.fetchInfo(isSameSeason, this.retroTournament);
      }
    },
    onPlayerSelected() {
      this.isRetroModeApplied = this.isRetroMode;
      this.mediaPlayerMode = 'player';
      this.checkSeason();
    },
    onTeamSelected() {
      this.isRetroModeApplied = this.isRetroMode;
      this.mediaPlayerMode = 'team';
      this.checkSeason();
    },
    onPlayChanged(play) {
      this.playInfo = {
        date: play.gameDate,
        playerName: play.player.name,
        playCategory: play.categoryName,
        teams: `${play.game.homeTeam.name} vs ${play.game.awayTeam.name}`,
        playerPhoto: play.player.photo,
        teamLogo: play.playerTeamLogo,
        teamId: play.teamId,
        categoryId: play.categoryId,
        jersey: play.jersey,
        mainCategoryName: play.mainCategory.name,
        categoriesNames: play.categoriesNames,
      };
    },
    async fetchInfo(isSameSeason, tournament) {
      try {
        let playlistSeasonIndex = this.playlistSeasonIndex;
        this.playlistTournament = tournament;
        this.seasonId = tournament.seasons[playlistSeasonIndex].id;
        const promises = [this.fetchPlays()];
        if (!isSameSeason) {
          const seasons = [this.seasonId];
          if (tournament.seasons[playlistSeasonIndex].related_seasons) {
            seasons.push(...tournament.seasons[playlistSeasonIndex].related_seasons.map(season => season.id));
          }
          promises.push(this.fetchSeasonsGamesAndTeams({ seasons }));
          promises.push(this.getPlayersBySeasons({ seasonId: this.seasonId }));
        }
        const [plays, gamesAndTeams] = await Promise.all(promises);
        this.plays = plays;
        if (!isSameSeason) {
          this.formatGamesAndTeams(gamesAndTeams);
        }
        this.formatPlays();
        this.generatePlayerQueue(this.currentCategories);
        this.showImage = false;
      } catch (e) {
        throw new Error(e);
      }
    },
    getBestPlaysCategoriesIds() {
      return Object.keys(bestPlaysCategories[this.mediaPlayerMode]).reduce((accumulator, categoryId) => {
        categoryId.split('-').forEach(categoryIdString => {
          accumulator.push(Number(categoryIdString));
        });
        return accumulator;
      }, []);
    },
    formatGamesAndTeams(gamesAndTeams) {
      this.teamsMap = gamesAndTeams.seasonsAndTeams.reduce((accumulator, season) => {
        season.teams.forEach(team => {
          if (!accumulator.has(team.team_id)) {
            accumulator.set(team.team_id, {
              name: team.acronym,
              logo: team.provisional_logo,
              id: team.team_id,
            });
          }
        });
        return accumulator;
      }, new Map());
      this.gamesMap = gamesAndTeams.seasonsAndGames.reduce((accumulator, season) => {
        season.games.forEach(game => {
          if (!accumulator.has(game.game_id)) {
            accumulator.set(game.game_id, {
              id: game.game_id,
              date: game.date,
              homeTeam: this.teamsMap.get(game.home_team),
              awayTeam: this.teamsMap.get(game.visiting_team),
            });
          }
        });
        return accumulator;
      }, new Map());
    },
    onPlayerModeClicked(modeId) {
      this.playerModeSelected = modeId;
    },
  },
  async created() {
    try {
      await this.fetchTournaments();
    } catch (e) {
      throw new Error(e);
    }
  },
};
</script>
<style lang="scss">
html > body {
  padding-top: 0 !important;
}

.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scoped>
.media-player {
  display: inline-block;

  &__mode {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 2rem 0 1rem;

    &__options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 90%;
      border-radius: 15px;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.19);
      background-color: #f6f6f6;
      font-size: 1.6rem;
      font-family: Avenir-Medium, sans-serif;
      color: #3e3e3e;
      position: relative;

      &__background {
        position: absolute;
        width: 50%;
        height: 100%;
        left: 0;
        background-color: #3c3c3c;
        border-radius: 15px;
        z-index: 0;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.21);

        &.retro {
          left: 50%;
        }
      }

      & > div {
        padding: 1.3rem;
        border-radius: 15px;
        cursor: pointer;
        z-index: 1;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;

        &.active {
          color: white;
        }
      }

      @media screen and (max-width: 620px) {
        width: 100%;
        font-size: 0.8rem;

        & > div {
          padding: 1.1rem 0;
          border-radius: 10px;
        }
      }
    }
  }
  &__categories {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 420px) {
      justify-content: space-between;
    }

    & > div {
      width: 16.25rem;
      height: 4.6rem;
      margin: 1rem;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Avenir-Pro-Bold, sans-serif;
      font-size: 1.25rem;
      border: solid 2px #d7dce2;
      color: #636462;

      &:first-child {
        @media screen and (max-width: 620px) {
          margin-right: 0.5rem;
        }
      }

      &:last-child {
        @media screen and (max-width: 620px) {
          margin-left: 0.5rem;
        }
      }

      @media screen and (max-width: 620px) {
        font-size: 0.8rem;
        height: 3rem;
        margin: 1rem 0;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    aspect-ratio: 9/5;
    background-image: url('/images/cortinilla_video.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      color: white;
      font-family: Avenir-Pro-Medium, sans-serif;
      font-size: 2rem;

      & > span {
        font-family: Avenir-Pro-Bold, sans-serif;
      }

      @media screen and (max-width: 460px) {
        font-size: 1rem;
      }
    }
  }
}
.button--active {
  border: solid 2px #0c98fd !important;
  background-color: #0c98fd !important;
  color: white !important;
}
.mediaplayer {
  width: 1053px;
  height: auto;
  margin: 0 auto;
  @media screen and (max-width: 1053px) {
    width: 953px;
  }
  @media screen and (max-width: 953px) {
    width: 853px;
  }
  @media screen and (max-width: 853px) {
    width: 768px;
  }
  @media screen and (max-width: 768px) {
    width: 653px;
  }
  @media screen and (max-width: 653px) {
    width: 553px;
  }
  @media screen and (max-width: 553px) {
    width: 453px;
  }
  @media screen and (max-width: 453px) {
    width: 353px;
  }
  @media screen and (max-width: 353px) {
    width: 280px;
  }
}
</style>
